import React, { useMemo, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth'; // do not remove, and only import here!
import SessionContext, { ISessionContext } from './SessionContext';

if (!firebase.apps.length) {
  const firebaseConfig = {
    apiKey: 'AIzaSyCH-ptDZxosyg0WqXZBMLH7UZhU1WRwgjM',
    authDomain: 'mitt-lager.firebaseapp.com',
    databaseURL: 'https://mitt-lager.firebaseio.com',
    projectId: 'mitt-lager',
    storageBucket: 'mitt-lager.appspot.com',
    messagingSenderId: '689064215131',
    appId: '1:689064215131:web:b604e25affe15d34ed757e',
  };
  firebase.initializeApp(firebaseConfig);
}

export default function SessionProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [session, setSession] = useState<firebase.User>(null);
  const [isLoading, setIsLoading] = useState(true);

  const context: ISessionContext = useMemo(() => {
    return {
      user: session,
      isLoading: isLoading,
      actions: {
        async signIn({
          email,
          password,
        }: {
          email: string;
          password: string;
        }): Promise<SignInMessage> {
          const signIn = await firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              return { success: true };
            })
            .catch((error) => {
              return { success: false, error: handleError(error) };
            });
          return signIn;
        },

        async signInWithToken({
          token,
        }: {
          token: string;
        }): Promise<SignInMessage> {
          await firebase.auth().signInWithCustomToken(token);
          const signIn = await firebase
            .auth()
            .signInWithCustomToken(token)
            .then(() => {
              return { success: true };
            })
            .catch((error) => {
              return { success: false, error: handleError(error) };
            });
          return signIn;
        },

        async signOut() {
          await firebase.auth().signOut();
        },

        async refreshSession(password: string): Promise<SignInMessage> {
          const user = firebase.auth().currentUser;
          const authed = await user
            .reauthenticateWithCredential(
              firebase.auth.EmailAuthProvider.credential(user.email, password)
            )
            .then((authed) => {
              if (authed.user.email === user.email) {
                return { success: true };
              }
            })
            .catch((error) => {
              console.log(error);
              return { success: false, error: handleError(error) };
            });
          return authed;
        },

        async resetPassword(token: string, password: string): Promise<void> {
          await firebase.auth().confirmPasswordReset(token, password);
        },

        async resetEmail(token: string): Promise<boolean> {
          const validCode = await firebase.auth().checkActionCode(token);
          if (validCode.data.email) {
            const prevEmail = validCode.data.email;
            await firebase.auth().applyActionCode(token);
            await firebase.auth().sendPasswordResetEmail(prevEmail);
            return true;
          }
          return false;
        },

        async changeEmail(
          newEmail: string,
          password: string
        ): Promise<SignInMessage> {
          const newSession = await this.refreshSession(password);
          if (newSession.success) {
            const sess = await session
              .updateEmail(newEmail)
              .then(() => {
                return { success: true };
              })
              .catch((error) => {
                return { success: false, error: handleError(error) };
              });
            return sess;
          }

          return newSession;
        },

        async setPassword(
          oldPassword: string,
          newPassword: string
        ): Promise<SignInMessage> {
          const newSession = await this.refreshSession(oldPassword);
          if (newSession.success) {
            const sess = await session
              .updatePassword(newPassword)
              .then(() => {
                return { success: true };
              })
              .catch((error) => {
                return { success: false, error: handleError(error) };
              });
            return sess;
          }
          return newSession;
        },
      },
    };
  }, [session, isLoading]);

  firebase.auth().onAuthStateChanged(function (user) {
    setSession(user);
    setIsLoading(false);
  });

  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  );
}
export interface SignInMessage {
  error?: Error;
  success: boolean;
}

interface Error {
  field: string;
  message: string;
}

function handleError(error): Error {
  switch (error.code) {
    case 'auth/wrong-password':
      return { field: 'password', message: 'Ditt lösenord är felaktigt' };
    case 'auth/invalid-email':
      return { field: 'email', message: 'E-posten är felaktig' };
    case 'auth/user-disabled':
      return {
        field: 'email',
        message: 'Din användare är avstängd, kontakta oss',
      };
    case 'auth/user-not-found':
      return {
        field: 'email',
        message: 'Ingen användare med denna E-posten kunde hittas',
      };
    default:
      return {
        field: 'newPassword',
        message: 'Okänt fel - försök igen senare',
      };
  }
}
