import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Container,
    Grid,
    GridItem,
    Link,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import Collapsable from 'components/collapsable/collapsable';
import { colors, elevation } from 'constants/constants';
import React, { useState } from 'react';
import { FAQSquareProps } from 'types/umbraco';

const FAQSquareBlock = ({ categories }: FAQSquareProps): JSX.Element => {
    // const [baseSize] = useMediaQuery('(max-width: 768px)')
    const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);
    return (
        <Box p={{ base: '10% 2%', md: '5em 5%', xl: '5em 0' }}>
            <Container maxW="7xl">
                <Box shadow={elevation['+80']} borderRadius="12px">
                    <Grid templateColumns={{ base: '1fr', md: '3fr 9fr' }}>
                        <GridItem
                            py={10}
                            px={6}
                            shadow={elevation['+80']}
                            zIndex={1}
                            borderRadius={{ base: "12px 12px 0px 0px", md: "12px 0px 0px 12px" }}>
                            <Text fontSize="2xl" fontWeight="600">
                                Se frågor om:
                            </Text>
                            <Box>
                                {categories.map((category, i) => (
                                    <Link
                                        onClick={() => setActiveCategoryIndex(i)}
                                        _hover={{ color: colors.primary[900] }}
                                        key={category.header}
                                        color={
                                            activeCategoryIndex === i
                                                ? colors.primary[900]
                                                : 'inherit'
                                        }
                                        borderBottom="1px"
                                        borderBottomColor={colors.secondary.borderGrey}
                                        py={{ base: 1, md: 4 }}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                        <Text fontSize="xl" fontWeight="600">
                                            {category.header}
                                        </Text>
                                        {activeCategoryIndex === i ? (
                                            <ChevronUpIcon w={6} h={10} />
                                        ) : (
                                            <ChevronDownIcon w={6} h={10} />
                                        )}
                                    </Link>
                                ))}
                            </Box>
                        </GridItem>
                        <GridItem>
                            <Box
                                px={6}
                                py={{ base: 5, md: 10 }}
                                backgroundColor={{ base: colors.secondary[900], md: colors.white }}
                                borderRadius={{ base: "0px 0px 12px 12px", md: "0px 12px 12px 0px" }}

                            >
                                {categories[activeCategoryIndex].description.length > 0 && <Box mb={10} padding={1}><Box style={{ lineHeight: 2 }} dangerouslySetInnerHTML={{ __html: categories[activeCategoryIndex].description }} ></Box></Box>}
                                <SimpleGrid columns={1} gap={5}>
                                    {categories[activeCategoryIndex].questions.map((q) => (
                                        <Collapsable
                                            question={q.question}
                                            answer={q.answer}
                                            key={q.question}
                                            HTMLanswer={true}
                                        />
                                    ))}
                                </SimpleGrid>
                            </Box>
                        </GridItem>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default FAQSquareBlock;
