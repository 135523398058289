import { Box, Container, Heading, Flex, Text } from '@chakra-ui/react';
import { CDBlockProps } from 'types/umbraco';
import { useEffect } from 'react';

export default function CDBlock({ 
  heading }: 
  CDBlockProps): JSX.Element {
    useEffect(() => {
      // Create a script element
      const script = document.createElement('script');
      script.id = 'CookieDeclaration';
      script.src = 'https://consent.cookiebot.com/fd32389e-5c40-4178-b5cf-dd2cc6d0bd87/cd.js';
      script.type = 'text/javascript';
      script.async = true;
  
      // Append the script to a container element in your component
      const container  = document.getElementById('cookieDeclaration'); // Replace with the actual container ID or selector
      if (container ) {
        container.appendChild(script);
    }
  
      // Cleanup: Remove the script element if the component unmounts
      return () => {
        if (container && script.parentNode === container) {
          container.removeChild(script);
        }
      };
    }, [heading]); // Re-run this effect whenever scriptCode changes
  return (
    <Box>
      <Container>
          <Flex
          h="100%"
          align="center"
          justify="center"
          pos="relative"
          zIndex="11"
          flexDirection="column">
          <Heading color="black" mb="1em" mt="1em">
            {heading}
          </Heading>
          <Container
          id="cookieDeclaration"
          maxW="75%">
          </Container>
        </Flex>
        </Container>
    </Box>
  );
}
