export const GA_TRACKING_ID = 'GTM-5DCQ7WW';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string): void => {
  window.dataLayer.push({
    event: 'pageview',
    page: {
      path: url,
    },
  });
  console.log('Sending pageview event', window.dataLayer);
};
export const purchase = (checkout: CheckoutObject, currency = 'SEK'): void => {
  window.dataLayer.push({
    event: 'eec.purchase',
    ecommerce: { purchase: checkout, currencyCode: currency },
  });
  console.log('Sending purchase event', window.dataLayer);
};
export const checkout = (checkout: CheckoutObject): void => {
  window.dataLayer.push({
    event: 'eec.checkout',
    ecommerce: { checkout: checkout },
  });
  console.log('Sending checkout event', window.dataLayer);
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action,
  category,
  label,
  value,
}: {
  action: string;
  category: string;
  label: string;
  value: number;
}): void => {
  window.dataLayer.push({
    event: action,
    data: {
      event_category: category,
      event_label: label,
      value: value,
    },
  });
};

export class CheckoutObject {
  actionField: ActionFieldObject;
  products: ProductFieldObject[];
}

export class ImpressionFieldObject {
  id: string;
  name: string;
  list: string;
  brand?: string;
  category: string;
  variant?: string;
  position: number;
  price: string;
}

export class ProductFieldObject {
  id: string;
  name: string;
  brand?: string;
  category: string;
  variant?: string;
  price: string;
  quantity = 1;
  dimension1: string;
  coupon?: string;
}

export class ActionFieldObject {
  id?: string;
  affiliation?: string;
  revenue?: string;
  tax?: string;
  step?: number;
  option?: string;
}

export enum EcActionType {
  Click = 'click',
  Detail = 'detail',
  Add = 'add',
  Remove = 'remove',
  Checkout = 'checkout',
  CheckoutOption = 'checkout_option',
  Purchase = 'purchase',
  Refund = 'refund',
  PromoClick = 'promo_click',
}
