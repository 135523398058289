import { createIcon } from '@chakra-ui/react';

const User = createIcon({
  displayName: 'userIcon',
  d:
    'M7.11114 5.16294C6.78224 5.3827 6.39556 5.5 6 5.5C5.46957 5.5 4.96086 5.28929 4.58579 4.91421C4.21071 4.53914 4 4.03043 4 3.5C4 3.10444 4.1173 2.71776 4.33706 2.38886C4.55682 2.05996 4.86918 1.80362 5.23463 1.65224C5.60009 1.50087 6.00222 1.46126 6.39018 1.53843C6.77814 1.6156 7.13451 1.80608 7.41421 2.08579C7.69392 2.36549 7.8844 2.72186 7.96157 3.10982C8.03874 3.49778 7.99913 3.89992 7.84776 4.26537C7.69638 4.63082 7.44004 4.94318 7.11114 5.16294ZM6.55557 2.66853C6.39112 2.55865 6.19778 2.5 6 2.5C5.73478 2.5 5.48043 2.60536 5.29289 2.79289C5.10536 2.98043 5 3.23478 5 3.5C5 3.69778 5.05865 3.89112 5.16853 4.05557C5.27841 4.22002 5.43459 4.34819 5.61732 4.42388C5.80004 4.49957 6.00111 4.51937 6.19509 4.48079C6.38907 4.4422 6.56725 4.34696 6.70711 4.20711C6.84696 4.06726 6.9422 3.88907 6.98079 3.69509C7.01937 3.50111 6.99957 3.30004 6.92388 3.11732C6.84819 2.93459 6.72002 2.77841 6.55557 2.66853ZM3.52513 7.52513C4.1815 6.86875 5.07174 6.5 6 6.5C6.92826 6.5 7.8185 6.86875 8.47487 7.52513C9.13125 8.1815 9.5 9.07174 9.5 10C9.5 10.1326 9.44732 10.2598 9.35355 10.3536C9.25979 10.4473 9.13261 10.5 9 10.5C8.86739 10.5 8.74021 10.4473 8.64645 10.3536C8.55268 10.2598 8.5 10.1326 8.5 10C8.5 9.33696 8.23661 8.70107 7.76777 8.23223C7.29893 7.76339 6.66304 7.5 6 7.5C5.33696 7.5 4.70107 7.76339 4.23223 8.23223C3.76339 8.70107 3.5 9.33696 3.5 10C3.5 10.1326 3.44732 10.2598 3.35355 10.3536C3.25979 10.4473 3.13261 10.5 3 10.5C2.86739 10.5 2.74021 10.4473 2.64645 10.3536C2.55268 10.2598 2.5 10.1326 2.5 10C2.5 9.07174 2.86875 8.1815 3.52513 7.52513Z',
  viewBox: '0 0 12 12',
});

const SmilingFace = createIcon({
  displayName: 'smilingFace',
  d:
    'M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM17 13C17 14.3261 16.4732 15.5979 15.5355 16.5355C14.5979 17.4732 13.3261 18 12 18C10.6739 18 9.40215 17.4732 8.46447 16.5355C7.52678 15.5979 7 14.3261 7 13H17Z',
  viewBox: '0 0 24 24',
});

const Heart = createIcon({
  displayName: 'heart',
  d:
    'M11 17C10.8684 17.0007 10.7379 16.9755 10.6161 16.9258C10.4943 16.876 10.3834 16.8027 10.29 16.71L2.51999 8.92999C1.54536 7.94516 0.998657 6.61555 0.998657 5.22999C0.998657 3.84442 1.54536 2.51481 2.51999 1.52999C3.50226 0.550497 4.83283 0.000457764 6.21999 0.000457764C7.60716 0.000457764 8.93773 0.550497 9.91999 1.52999L11 2.60999L12.08 1.52999C13.0623 0.550497 14.3928 0.000457764 15.78 0.000457764C17.1672 0.000457764 18.4977 0.550497 19.48 1.52999C20.4546 2.51481 21.0013 3.84442 21.0013 5.22999C21.0013 6.61555 20.4546 7.94516 19.48 8.92999L11.71 16.71C11.6166 16.8027 11.5057 16.876 11.3839 16.9258C11.2621 16.9755 11.1316 17.0007 11 17ZM6.21999 1.99999C5.79667 1.99807 5.37717 2.08017 4.9858 2.24152C4.59443 2.40288 4.23897 2.64028 3.93999 2.93999C3.33605 3.54711 2.99703 4.36863 2.99703 5.22499C2.99703 6.08134 3.33605 6.90286 3.93999 7.50999L11 14.58L18.06 7.50999C18.6639 6.90286 19.003 6.08134 19.003 5.22499C19.003 4.36863 18.6639 3.54711 18.06 2.93999C17.4437 2.35769 16.6279 2.03328 15.78 2.03328C14.9321 2.03328 14.1163 2.35769 13.5 2.93999L11.71 4.73999C11.617 4.83371 11.5064 4.90811 11.3846 4.95888C11.2627 5.00965 11.132 5.03578 11 5.03578C10.868 5.03578 10.7373 5.00965 10.6154 4.95888C10.4936 4.90811 10.383 4.83371 10.29 4.73999L8.49999 2.93999C8.20102 2.64028 7.84556 2.40288 7.45419 2.24152C7.06282 2.08017 6.64332 1.99807 6.21999 1.99999Z',
  viewBox: '0 0 22 17',
});

const Eye = createIcon({
  displayName: 'eye',
  d:
    'M11.7301 4.99999C17.7101 4.81999 21.2301 10.39 21.8701 11.5C21.9578 11.652 22.004 11.8245 22.004 12C22.004 12.1755 21.9578 12.348 21.8701 12.5C21.0101 14 17.8001 18.86 12.2701 19H12.0201C6.13007 19 2.76007 13.59 2.13007 12.5C2.0423 12.348 1.99609 12.1755 1.99609 12C1.99609 11.8245 2.0423 11.652 2.13007 11.5C3.00007 9.99999 6.20007 5.13999 11.7301 4.99999ZM4.22007 12C5.10007 13.41 7.91007 17.1 12.2201 17C16.2201 16.9 18.8001 13.61 19.8301 12C18.9401 10.59 16.1201 6.88999 11.8301 6.99999C7.83007 7.09999 5.22007 10.39 4.22007 12ZM10.0556 9.08986C10.6311 8.70527 11.3078 8.5 12.0001 8.5C12.9283 8.5 13.8186 8.86875 14.4749 9.52513C15.1313 10.1815 15.5001 11.0717 15.5001 12C15.5001 12.6922 15.2948 13.3689 14.9102 13.9445C14.5256 14.5201 13.979 14.9687 13.3395 15.2336C12.6999 15.4985 11.9962 15.5678 11.3172 15.4327C10.6383 15.2977 10.0147 14.9644 9.52519 14.4749C9.03571 13.9854 8.70236 13.3617 8.56731 12.6828C8.43227 12.0039 8.50158 11.3001 8.76648 10.6606C9.03139 10.0211 9.47999 9.47444 10.0556 9.08986ZM11.1667 13.2472C11.4134 13.412 11.7034 13.5 12.0001 13.5C12.3979 13.5 12.7794 13.342 13.0607 13.0607C13.342 12.7794 13.5001 12.3978 13.5001 12C13.5001 11.7033 13.4121 11.4133 13.2473 11.1666C13.0824 10.92 12.8482 10.7277 12.5741 10.6142C12.3 10.5006 11.9984 10.4709 11.7074 10.5288C11.4165 10.5867 11.1492 10.7296 10.9394 10.9393C10.7296 11.1491 10.5868 11.4164 10.5289 11.7074C10.471 11.9983 10.5007 12.2999 10.6142 12.574C10.7278 12.8481 10.92 13.0824 11.1667 13.2472Z',
  viewBox: '0 0 24 24',
});

const Menu = createIcon({
  displayName: 'Menu',
  viewBox: '0 0 12 12',
  path: [
    <path
      key={1}
      d="M1 6.5H10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={2}
      d="M1 3.5H10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={3}
      d="M1 9.5H10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

const ToggleArrow = createIcon({
  displayName: 'ToggleArrow',
  viewBox: '0 0 12 7',
  d:
    'M5.99994 6.5C5.86833 6.50076 5.73787 6.47554 5.61603 6.42577C5.4942 6.37601 5.38338 6.30268 5.28994 6.21L1.28994 2.21C1.1967 2.11676 1.12274 2.00607 1.07228 1.88425C1.02182 1.76243 0.99585 1.63186 0.99585 1.5C0.99585 1.36814 1.02182 1.23757 1.07228 1.11575C1.12274 0.99393 1.1967 0.88324 1.28994 0.790002C1.38318 0.696763 1.49387 0.622802 1.61569 0.572342C1.73751 0.521882 1.86808 0.495911 1.99994 0.495911C2.1318 0.495911 2.26237 0.521882 2.38419 0.572342C2.50601 0.622802 2.6167 0.696763 2.70994 0.790002L5.99994 4.1L9.29994 0.920002C9.39193 0.817712 9.50404 0.735503 9.62925 0.678517C9.75447 0.621531 9.89009 0.590994 10.0276 0.588815C10.1652 0.586635 10.3017 0.612861 10.4287 0.665852C10.5556 0.718843 10.6703 0.797459 10.7655 0.896784C10.8607 0.996108 10.9343 1.114 10.9819 1.2431C11.0294 1.37219 11.0498 1.50971 11.0418 1.64704C11.0338 1.78438 10.9975 1.91858 10.9352 2.04126C10.873 2.16393 10.7861 2.27244 10.6799 2.36L6.67994 6.22C6.49707 6.39632 6.25394 6.49643 5.99994 6.5Z',
});

const CheckMark = createIcon({
  displayName: 'CheckMark',
  viewBox: '0 0 24 24',
  d:
    'M9.86002 18C9.72291 17.9995 9.58735 17.9709 9.46177 17.9158C9.33618 17.8608 9.22326 17.7805 9.13002 17.68L4.27002 12.51C4.08835 12.3164 3.99103 12.0585 3.99947 11.7932C4.00791 11.5278 4.12142 11.2766 4.31502 11.095C4.50863 10.9133 4.76648 10.816 5.03184 10.8244C5.29721 10.8329 5.54835 10.9464 5.73002 11.14L9.85003 15.53L18.26 6.32997C18.3454 6.22369 18.4515 6.13599 18.572 6.07223C18.6925 6.00848 18.8247 5.97004 18.9606 5.95925C19.0965 5.94847 19.2331 5.96557 19.3622 6.00952C19.4912 6.05347 19.6099 6.12333 19.7109 6.21482C19.8119 6.3063 19.8932 6.41748 19.9497 6.54151C20.0062 6.66555 20.0368 6.79983 20.0395 6.93611C20.0423 7.07239 20.0171 7.20778 19.9656 7.33398C19.9141 7.46018 19.8373 7.57452 19.74 7.66997L10.6 17.67C10.5077 17.7723 10.3951 17.8545 10.2695 17.9113C10.1439 17.9681 10.0079 17.9983 9.87002 18H9.86002Z',
});

export { User, SmilingFace, Heart, Eye, Menu, ToggleArrow, CheckMark };
