import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { colors } from 'constants/constants';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { HeroProps } from 'types/umbraco';

export default function HeroBlock({
  heading,
  text,
  textAlignment,
  blockMedia,
  children,
}: HeroProps): JSX.Element {
  const router = useRouter();
  const home = router.route === '/';
  console.log(blockMedia, "t")
  const textAlign = textAlignment === 'Vänster' ? 'left' : textAlignment === 'Höger' ? 'right' : 'center';
  return (
    <Box
      p={{ base: '2em 5%', md: '5em 5%', xl: '5em 0' }}
      h={{ base: home ? '75vh' : 300, sm: 400, md: 500 }}
      bg={colors.neutrals[100]}
      pos="relative">
      <Container maxW="7xl" h="100%">
        <Flex
          h="100%"
          align={textAlign}
          justify="center"
          pos="relative"
          zIndex="11"
          flexDirection="column">
          <Heading color="white" mb=".5em">
            {heading}
          </Heading>
          <Text color="white">{text}</Text>
          {children}
        </Flex>
      </Container>

      {blockMedia &&
        blockMedia._url &&
        (blockMedia.mediaTypeAlias === 'File' ? (
          <video
            autoPlay={true}
            loop={true}
            playsInline={true}
            muted={true}
            style={{
              pointerEvents: 'none',
              userSelect: 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}>
            <source src={blockMedia._url} />
          </video>
        ) : (
          <>
            <Image
              src={blockMedia._url}
              layout="fill"
              className="heroObjectFit"
              priority={true}
              loading="eager"
              alt={blockMedia.altText ?? 'hero image'}
            />
            <style jsx global>{`
              .heroObjectFit {
                object-fit: cover;
              }
            `}</style>
          </>
        ))}

      <Box
        bg={colors.black}
        pos="absolute"
        w="100%"
        h="100%"
        top="0"
        left="0"
        opacity={0.1}
        zIndex="1"
      />
    </Box>
  );
}
