import { useState } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { colors } from 'constants/constants';
import Image from 'next/image';
import Link from 'next/link';
import { HighlightsProps, NestedHighlight } from 'types/umbraco';
import Header from 'components/header/header';

function withLink(
  i: number,
  link: string,
  isExternal: boolean,
  element: JSX.Element
): JSX.Element {
  if (link) {
    return (
      <Link href={link} key={i}>
        <a target={isExternal ? '_blank' : '_self'}>{element}</a>
      </Link>
    );
  }

  return <div key={i}>{element}</div>;
}

export default function HighlightsBlock({
  nestedHighlights,
}: HighlightsProps): JSX.Element {


// const categories = [];
// nestedHighlights.forEach((element) => 
// categories.push(element.kategorier))
// categories.map((c, i) => {
//   console.log(c)
// })



  const [hoverIndex, setHoverIndex] = useState(-1)
  return (

    <Box p={{ base: '2em 5%', md: '5em 5%', xl: '5em 0' }}>
      <Container maxW="7xl">
        {/* {nestedHighlights &&
          nestedHighlights.map((c) => {
          return (
            <h1>{c.kategorier}</h1>

          )
      })} */}

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={10}>
          {nestedHighlights &&
            nestedHighlights.map((block, i) => {
              const { isExternal, link } = formatLink(block);
              return withLink(
                i,
                link,
                isExternal,
                <Box
                  onMouseEnter={() => setHoverIndex(i)}
                  onMouseLeave={() => setHoverIndex(-1)}
                  key={i}
                  color={colors.black}
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  _hover={{ transform: 'translateY(-1.5em)', color: colors.primary[900] }}
                  transition=".15s all ease-in-out">
                  {block.highlightImage && (
                    <Box
                      w="100%"
                      h="240px"
                      pos="relative"
                      overflow="hidden"
                      borderRadius="1em">
                      <Image
                        src={`${block.highlightImage._url}?width=400`}
                        layout="fill"
                        className="highlightObjectFit"
                        priority={true}
                        loading="eager"
                        alt={
                          block.highlightImage.altText ??
                          block.highlightImage.name
                        }
                      />
                      <style jsx global>{`
                        .highlightObjectFit {
                          object-fit: cover;
                        
                        }
                      `}</style>
                    </Box>
                  )}
                  
                  <Heading
                    mt=".8em"
                    mb=".5em"
                    color="inherit"
                    as="h3"
                    size="md"
                    fontWeight="600">
                    {block.highlightHeading}
                  </Heading>
                  <Text color={colors.neutrals[700]}>
                    {block.highlightText}
                  </Text>
                  {link && block.highlightLinkText && <Text style={{ marginTop: 'auto', textDecoration: "underline", display: 'flex', alignItems: 'center', transition: ".15s all ease-in-out" }}>
                    {block.highlightLinkText} <ChevronRightIcon h={6} w={6} ml={1} transform={hoverIndex === i ? "translateX(3rem)" : "translateX(0)"} />
                  </Text>}
                  
                  {link && block.readMoreButton && <Text className="linkButton">
                    {block.readMoreButton}
                    <style jsx global>{`
                        .linkButton{
                          display: -webkit-inline-flex;
                          font-weight: var(--chakra-fontWeights-semibold);
                          margin-top: 20px;
                          padding: 5px 15px;
                          border-radius: 8px;
                          width: fit-content;
                          background: #FF595E;
                          color: #ffffff;
                          background-color: #F27039;
                        }
                      `}</style>
                  </Text>
                  }
                  

                </Box>
              );

            })}
        </SimpleGrid>
      </Container>

    </Box>
  );
}

function formatLink(
  block: NestedHighlight
): { link: string; isExternal: boolean } {
  const externalLink = block.externalLink?.trim();
  const internalLink = block.highlightLink?._url?.replace('/website', '');
  const isExternal = !!externalLink?.length;

  return { link: isExternal ? externalLink : internalLink, isExternal };
}
