import { extendTheme } from '@chakra-ui/react';
import { colors, elevation } from 'constants/constants';

export const theme = extendTheme({
  fonts: {
    heading: `Montserrat,-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `Montserrat,-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  styles: {
    global: {
      '.umb-text-block h2, .umb-cta-block h2': {
        fontSize: '2.25rem',
        lineHeight: '2.5rem',
        fontWeight: 700,
      },
      '.umb-text-block h3, .umb-cta-block h3': {
        fontSize: '1.8rem',
        lineHeight: '1.9rem',
        fontWeight: 700,
      },
      '.umb-text-block h2, .umb-text-block h3, .umb-cta-block h2, .umb-cta-block h3': {
        margin: '.6em 0',
      },
      '.umb-text-block ul, .umb-text-block ol': {
        margin: '0 1em',
        padding: '1em',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 8,
      },
      variants: {
        primary: {
          bg: colors.primary[900],
          color: colors.white,
          fontWeight: 400,
          paddingX: 12,
          _hover: { bg: colors.black, textDecoration: 'none' },
        },
        darkSolid: {
          bg: colors.neutrals[900],
          color: colors.white,
        },
        solid: {
          bg: colors.system.error[900],
          color: colors.white,
          fontSize: '14px',
        },
        ml: {
          bg: colors.primary[900],
          color: colors.white,
          _hover: { bg: colors.black },
        },
        success: {
          bg: colors.system.success[900],
          color: colors.white,
        },
        outline: {
          color: colors.black,
          bg: colors.white,
          border: '1px solid black',
          borderColor: 'black',
        },
        inlineBtn: {
          bg: 'transparent',
          p: 0,
          h: 'auto',
          _hover: { bg: 'transparent' },
        },
        logout: {
          bg: colors.system.error[900],
          color: colors.white,
          _hover: { bg: colors.system.error[500] },
        },
        bookingBtn: {
          boxShadow: elevation['+40'],
          pos: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '1em',
          width: '4em',
          height: '4em',
          borderRadius: '4em',
          bg: colors.system.success[900],
          color: 'white',
        },
      },
    },
    Container: {
      baseStyle: {
        maxW: '7xl',
      },
    },
    Heading: {
      variants: {
        productCardHeading: {
          fontWeight: 'bold',
          fontSize: 'xl',
        },
        checkoutBorderHeading: {
          as: 'h2',
          borderBottom: '1px solid',
          borderColor: colors.secondary[500],
          pb: 3,
          mb: 3,
        },
        semiBold: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '29px',
          color: colors.secondary.darkGrey,
        },
        moneyLargeBold: {
          as: 'h2',
          fontStyle: 'Normal',
          fontWeight: 'bold',
          fontSize: '80px',
          lineHeight: '106px',
        },
      },
    },
    Text: {
      variants: {
        preamble: {
          fontSize: 20,
        },
        productCardPreamble: {
          color: colors.neutrals[300],
        },
        productCardMoney: {
          fontWeight: 'bold',
          fontSize: 'lg',
          color: colors.system.success[900],
        },
        checkoutFacilityName: {
          fontSize: 'xs',
        },
        checkoutProductName: {},
        checkoutProductSize: {
          fontSize: 'xl',
          fontWeight: '600',
          color: colors.secondary.darkGrey,
        },
        checkoutProductDescription: {},
        checkoutProductMoney: {
          fontWeight: '600',
          fontSize: 'xl',
          color: colors.system.success[900],
        },
      },
    },
    FormLabel: {
      variants: {
        reactSelectLabel: {
          pos: 'absolute',
          top: '1.25em',
          left: '50px',
          color: colors.secondary[700],
        },
      },
    },
    Tooltip: {
      baseStyle: {
        p: 2,
        borderRadius: '.5em',
      },
      variants: {
        reactSelectTooltip: {
          bg: colors.system.success[900],
          color: 'white',
        },
      },
    },
  },
});
