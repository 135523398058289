import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { colors } from 'constants/constants';
import React from 'react';
import { DayPicker } from 'react-day-picker';
import sv from 'date-fns/locale/sv';

const today = new Date(new Date().setHours(0, 0, 0, 0));
const twoWeeks = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);
export default function DatePicker({
  onDateChange,
  value,
  children,
}: {
  onDateChange(date: Date): void;
  value: Date;
  children: React.ReactNode;
}): JSX.Element {
  const modifiersStyles = {
    selected: {
      backgroundColor: colors.primary[900],
      borderRadius: 0,
    },
    today: {
      color: 'black',
    },
    container: {
      backgroundColor: 'red',
    },
  };

  return (
    <Popover>
      {(state) => (
        <>
          <PopoverTrigger>
            <Button
              borderColor="#ddd"
              variant="outline"
              fontWeight="normal"
              rightIcon={<ChevronDownIcon />}>
              {children}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w={'fit-content'}>
              <PopoverBody>
                <DayPicker
                  disabled={[{ before: today }, { after: twoWeeks }]}
                  selected={value}
                  mode="single"
                  locale={sv}
                  showWeekNumber={true}
                  onDayClick={(day) => {
                    onDateChange(day);
                    state.onClose();
                  }}
                  modifiersStyles={modifiersStyles}
                />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
}

const MONTHS = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];
const WEEKDAYS_LONG = [
  'Söndag',
  'Måndag',
  'Tisdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lördag',
];
const WEEKDAYS_SHORT = ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'];
