import { useContext } from 'react';
import { useRouter } from 'next/router';
import { Button, ButtonProps } from '@chakra-ui/react';
import { User } from '../../../constants/icons';
import SessionContext from 'session/SessionContext';
import { colors } from 'constants/constants';

export default function MyPages(props: ButtonProps): JSX.Element {
  const session = useContext(SessionContext);
  const router = useRouter();

  const handleClick = () => {
    router.replace(session.user ? '/mypages' : '/login');
  };

  return (
    <Button
      height="2.5em"
      fontSize="14px"
      leftIcon={<User />}
      onClick={handleClick}
      backgroundColor={colors.primary[900]}
      _hover={
        { backgroundColor: colors.secondary[900] }
      }
      {...props}>
      Mina sidor
    </Button>
  );
}
