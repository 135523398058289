import React from 'react';
import { SimpleGrid, Box, Heading, Text } from '@chakra-ui/react';
import { colors } from 'constants/constants';

const ContactInfo = (): JSX.Element => {
    return (
        <Box mt={10}>
            <Box style={{ fontSize: 20, textAlign: 'center', marginTop: 5, marginBottom: '20px' }} >För direkt kontakt eller övriga frågor går det bra att:</Box>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt={3} mb={3}>
                {/* <Box>
                    <Heading as="h4" size="md">
                        Chatta
                    </Heading>
                    <Text>
                        Vår chatt är öppen alla vardagar mellan 08.00 – 17.00, du kan även
                        skriva til oss när vi är offline, så svarar vi så snart vi kan.
                    </Text>
                </Box>  */} 
                <Box>
                    <Heading as="h4" size="md">
                        Maila
                    </Heading>
                    <Text>
                        Om du inte vill chatta med oss så är vi självklart tillgängliga på
                        e-post, fyll i formuläret ovan eller, maila oss på{' '}
                        <a
                            style={{ color: colors.primary[900] }}
                            href="mailto:kundservice@mittlager.se">
                            kundservice@mittlager.se
                        </a>
                    </Text>
                </Box>
                <Box>
                    <Heading as="h4" size="md">
                        Ringa
                    </Heading>
                    <Text>Ni är även välkommen att ringa oss om ni har frågor eller funderingar kring våra förråd på <a
                        style={{ color: colors.primary[900] }}
                        href="tel:0102636900">010 263 6900</a></Text>
                </Box>
            </SimpleGrid>
        </Box>
    )
}

export default ContactInfo