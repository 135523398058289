import React from 'react';
import MyPages from '../mypages/mypages';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MainMenu } from 'types/umbraco';
import Logo from '../../../public/images/logo.svg';
import { colors } from 'constants/constants';

type Props = {
  menu?: MainMenu;
  scrolled: boolean;
};

export default function Navigation({ scrolled, menu }: Props): JSX.Element {
  const router = useRouter();
  const home = router.route === '/';
  const fontColor =
    home && !scrolled
      ? 'white'
      : scrolled
        ? colors.neutrals[900]
        : colors.neutrals[900];
  return (
    <>
      <Box>
        <Link href="/">
          <a aria-label="Logga">
            <Logo
              fill={
                home && !scrolled
                  ? 'white'
                  : scrolled
                    ? colors.primary[900]
                    : colors.primary[900]
              }
              height="2.5em"
            />
          </a>
        </Link>
      </Box>

      <Box w="100%" display={{ base: 'none', md: 'flex' }} alignItems="center">
        <Flex flex="1" alignItems="center" maxW="xl" margin="auto">
          <Flex justifyContent="space-around" flex="1" color={fontColor}>
            {menu?.items?.map((x) => {
              return (
                <Box fontSize="14px" key={x.path}>
                  {x.children === undefined ? (
                    <Link href={x.path}>
                      <a>{x.name}</a>
                    </Link>
                  ) : (
                    <Menu isLazy={true}>
                      <Box>
                        <MenuButton >
                          {x.name}
                          <ChevronDownIcon ml={3} w={5} h={5} />
                        </MenuButton>
                      </Box>
                      <MenuList color={colors.black}>
                        <Link href={`/anlaggningar`}>
                          <MenuItem>
                            Alla anläggningar
                          </MenuItem>
                        </Link>
                        {x.children?.map((y) => {
                          return (
                            <Link href={`/anlaggning/${y.slug}`} key={y.id}>
                              <MenuItem
                                key={y.id}
                                as="a"
                                cursor="pointer"
                                py={{ base: 0, md: 'inherit' }}
                                lineHeight={{ base: 2, md: 'inherit' }}>
                                {y.name}
                              </MenuItem>
                            </Link>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  )}
                </Box>
              );
            })}
          </Flex>
        </Flex>
        <Box>
          <MyPages />
        </Box>
      </Box>
    </>
  );
}
