import { Box, Container } from '@chakra-ui/react';
import { TextBlockProps } from 'types/umbraco';

export default function TextBlock({ text }: TextBlockProps): JSX.Element {
  return (
    <Box
      className="umb-text-block"
      p={{ base: '2em 5%', md: '5em 5% 3em 5%', xl: '5em 0 3em 0' }}>
      <Container
        maxW="4xl"
        dangerouslySetInnerHTML={{ __html: text }}></Container>
    </Box>
  );
}
