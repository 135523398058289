import firebase from 'firebase/app';

import React from 'react';
import { SignInMessage } from './SessionProvider';

export interface State {
  signedIn: boolean;
  user: firebase.auth.UserCredential;
}

export interface ISessionContext {
  user: firebase.User;
  isLoading: boolean;
  actions: {
    signIn({
      email,
      password,
    }: {
      email: string;
      password: string;
    }): Promise<SignInMessage>;
    signInWithToken({ token }: { token: string }): Promise<SignInMessage>;
    signOut(): Promise<void>;
    refreshSession(password: string): Promise<SignInMessage>;
    changeEmail(newEmail: string, password: string): Promise<SignInMessage>;
    resetEmail(token: string): Promise<boolean>;
    resetPassword(token: string, password: string): Promise<void>;
    setPassword(
      oldPassword: string,
      newPassword: string
    ): Promise<SignInMessage>;
  };
}
const SessionContext = React.createContext<ISessionContext>(null);

export default SessionContext;
