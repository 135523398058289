import { Box, Container, Heading } from '@chakra-ui/react';
import { colors } from 'constants/constants';
import React from 'react';
import { IFrameProps } from 'types/umbraco';

export default function IFrameBlock({
  iframekod,
  heading,
}: IFrameProps): JSX.Element {
  return (
    <Box
      className="umb-text-block"
      p={{ base: '2em 5%', md: '2em 5% 3em 5%', xl: '2em 0 3em 0' }}>
      <Heading
        mt=".8em"
        mb=".5em"
        px="1rem"
        color={colors.black}
        as="h3"
        size="md"
        fontWeight="600">
        {heading ?? 'Låna släp'}
      </Heading>
      <Container
        maxW="100%"
        dangerouslySetInnerHTML={{ __html: iframekod }}></Container>
    </Box>
  );
}
