import { Box, Collapse, Flex, Heading } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { colors } from 'constants/constants';

type Props = {
  question: string;
  answer: string;
  HTMLanswer?: boolean
};

export default function Collapsable({ question, answer, HTMLanswer = false }: Props): JSX.Element {
  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  return (
    <>
      {question && answer && (
        <Box mb={show ? '2em' : '1em'} borderBottomWidth={1}>
          <Flex
            pb=".5em"
            height="auto"
            flex="auto"
            alignItems="center"
            justifyContent="space-between"
            onClick={handleToggle}
            cursor="pointer"

          >
            <Heading
              as="h2"
              fontSize="l"
              fontWeight="bold"
              lineHeight="1.35"
              color={HTMLanswer ? { base: colors.white, md: colors.black } : colors.black}
            >
              {question}
            </Heading>

            {show ? (
              <ChevronUpIcon fontSize="1.5em" color={HTMLanswer ? { base: colors.white, md: colors.black } : colors.black} />
            ) : (
              <ChevronDownIcon fontSize="1.5em" color={HTMLanswer ? { base: colors.white, md: colors.black } : colors.black} />
            )}
          </Flex>

          <Collapse in={show}>
            {HTMLanswer ? <Box className='FAQSquareBlock__answer' color={{ base: colors.white, md: colors.black }} mb="1em" fontSize={{ base: 'sm' }} dangerouslySetInnerHTML={{ __html: answer }} /> : <Box mb="1em">{answer}</Box>}
          </Collapse>
        </Box>
      )
      }
    </>
  );
}
