import React, { useState, useEffect, useCallback } from 'react';
import { ContactFormData, ContactFormProps } from 'types/umbraco';
import {
  Box,
  Container,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Text,
  Button,
  Heading,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react';
import 'react-day-picker/dist/style.css';
import { GetFacilities, GetAvailableProductsByFacility } from 'helpers/coreApi';
import { FacilityDto } from 'types/generic';
import DatePicker from 'components/checkout/DatePicker';
import ContactInfo from 'components/contact/contactInfo';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { postApi } from 'helpers/fetchApi';

const formFields = [
  { name: 'Kontaktperson', placeHolder: 'Ange namn', data: 'name' },
  { name: 'E-post', placeHolder: 'Ange E-post', data: 'email' },
  { name: 'Telefonnummer', placeHolder: 'Ange telefonnummer', data: 'phone' },
];

const companyFormFields = [
  { name: 'Företagsnamn', placeHolder: 'Företagets namn', data: 'companyName' },
  { name: 'Adress', placeHolder: 'Ange adress', data: 'streetAddress' },
  {
    name: 'Postnummer och ort',
    placeHolder: 'Ange postnummer och ort',
    data: 'postalCode',
  },
  {
    name: 'Organisationsnummer',
    placeHolder: 'Ange organisationsnummer',
    data: 'orgNumber',
  },
];

const today = new Date(new Date());

const emptyData = {
  name: '',
  email: '',
  phone: '',
  companyName: '',
  streetAddress: '',
  postalCode: '',
  orgNumber: '',
};

const successToast: UseToastOptions = {
  title: 'Meddelande skickat',
  description: 'Tack för ditt meddelande.',
  status: 'success',
  duration: 5000,
  isClosable: true,
  position: 'top',
};
const failToast: UseToastOptions = {
  title: 'Något gick fel',
  description: 'Något gick fel, var god försök igen senare.',
  status: 'error',
  duration: 5000,
  isClosable: true,
  position: 'top',
};
const missingDataToast: UseToastOptions = {
  title: 'Något saknas',
  description: 'Var god fyll i alla fält.',
  status: 'error',
  duration: 5000,
  isClosable: true,
  position: 'top',
};
const invalidEmailToast: UseToastOptions = {
  title: 'Felaktig e-post',
  description: 'Var god ange en korrekt e-postadress.',
  status: 'error',
  duration: 5000,
  isClosable: true,
  position: 'top',
};
const missingFacilityToast: UseToastOptions = {
  title: 'Ort saknas',
  description: 'Var god ange en korrekt ort.',
  status: 'error',
  duration: 5000,
  isClosable: true,
  position: 'top',
};
const missingProductToast: UseToastOptions = {
  title: 'Förrådsstorlek saknas',
  description: 'Var god ange en korrekt förrådsstorlek.',
  status: 'error',
  duration: 5000,
  isClosable: true,
  position: 'top',
};

const ContactFormBlock = ({
  heading,
  isCompany,
  showContact,
}: ContactFormProps): JSX.Element => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [data, setData] = useState(emptyData);
  const [facility, setFacility] = useState<FacilityDto | Record<string, never>>(
    {}
  );
  const [facilities, setFacilities] = useState<FacilityDto[]>([]);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');
  const [comment, setComment] = useState('');
  const [date, setDate] = useState<Date>();
  const [token, setToken] = useState<string>('');

  const showToast = useToast();

  const resetForm = () => {
    setData(emptyData);
    setProduct('');
    setComment('');
    setFacility({});
    setDate(new Date());
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    setToken(token);
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const handleSubmit = async () => {
    handleReCaptchaVerify();
    const response = await fetch('/api/recaptcha/verifytoken/', {
      method: 'POST',
      body: JSON.stringify({
        token,
        secret: process.env.NEXT_PUBLIC_RECAPTCHA_SECRET_KEY,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const res = await response.json();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailValid = emailRegex.test(data.email);
    if (res?.status === 'ok' && emailValid) {
      const postData: ContactFormData = {
        ...data,
        isCompany: isCompany === 'Ja',
        orgNumber: data.orgNumber,
        facility: facility?.name,
        product,
        comment,
        desiredDate: date?.toLocaleDateString('sv-SE'),
      };
      console.log(postData.isCompany);
      console.log(typeof(postData.isCompany));
      if(postData.isCompany){
        if(postData.facility === undefined){
          showToast(missingFacilityToast);
        }
        else if(postData.product === ""){
          showToast(missingProductToast);
        }
        else{
          const postRes = await postApi('/Public/contact', postData);
          if (postRes.status === 200) {
            showToast(successToast);
            resetForm();
          } else {
            showToast(missingDataToast);
          }
        }
      }
      else{
        if(postData.facility === undefined){
          showToast(missingFacilityToast);
        }
        else{
          const postRes = await postApi('/Public/contact', postData);
          if (postRes.status === 200) {
            showToast(successToast);
            resetForm();
          } else {
            showToast(missingDataToast);
          }
        }
      }
    } else {
      if (!emailValid) {
        showToast(invalidEmailToast);
      }
      showToast(failToast);
    }
  };

  useEffect(() => {
    const getFacilities = async () => {
      const facilities: FacilityDto[] = await GetFacilities();
      setFacilities(facilities);
    };
    getFacilities();
    setDate(today);
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      const products = await GetAvailableProductsByFacility();
      if (facility && facility['slug']) {
        setProducts(products[facility['slug']]);
      }
    };
    if (facility && Object.keys(facility)) {
      getProducts();
    }
  }, [facility]);

  const filteredFormFields =
    isCompany === 'Ja'
      ? [...formFields, ...companyFormFields]
      : [...formFields];

  return (
    <Box
      p={{ base: '.2rem 5%', md: '5em 5%', xl: '5em 0' }}
      className="umb-cta-block">
      <Container maxW="7xl">
        {heading && <Heading>{heading}</Heading>}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          {filteredFormFields.map((x) => (
            <FormControl key={x.name} isRequired>
              <FormLabel style={styles.label} htmlFor="name">
                {x.name}
              </FormLabel>
              <Input
                id="name"
                placeholder={x.placeHolder}
                name={x.data}
                value={data[x.data]}
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>
          ))}
          {isCompany === 'Ja' && (
          <>
          <FormControl isRequired>
            <FormLabel style={styles.label}>Önskad förrådsstorlek</FormLabel>
            {facility && (
              <Select
                placeholder="Välj förrådsstorlek"
                onChange={(e) => setProduct(e.target.value)}>
                {facility &&
                  products.map((x) => (
                    <option key={x.id} value={x.name}>
                      {x.name}
                    </option>
                  ))}
              </Select>
            )}
          </FormControl>
          </>
          )}          
          <FormControl isRequired>
            <FormLabel style={styles.label}>Önskad ort</FormLabel>
            <Select
              placeholder="Välj anläggning"
              onChange={(e) =>
                setFacility(
                  facilities.filter((x) => x.name === e.target.value)[0]
                )
              }>
              {facilities.map((x) => (
                <option key={x.id} value={x.name}>
                  {x.name}
                </option>
              ))}
            </Select>
          </FormControl>
          {isCompany === 'Ja' && (
          <>          
          <Box>
            <Text style={styles.label} mb={2}>
              Önskat inflyttningsdatum
            </Text>
            {date && (
              <DatePicker
                value={date}
                onDateChange={(date) => {
                  setDate(date);
                }}>
                {date.toLocaleDateString()}
              </DatePicker>
            )}
          </Box>
          </>
          )} 
        </SimpleGrid>
        <Box marginY={10}>
          <FormControl>
            <FormLabel style={styles.label}>Kommentar</FormLabel>
            <Textarea
              rows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormControl>
        </Box>
        <Button variant="ml" onClick={handleSubmit}>
          Skicka
        </Button>
        {showContact === 'Ja' && <ContactInfo />}
      </Container>
    </Box>
  );
}; 

export default ContactFormBlock;

const styles = {
  label: { fontSize: 14, fontWeight: 600 },
};
