import { Box, SimpleGrid, Container } from '@chakra-ui/react';
import Collapsable from 'components/collapsable/collapsable';
import { FAQProps } from 'types/umbraco';

export default function FAQBlock({ questions }: FAQProps): JSX.Element {
  if (!questions || !questions.length) {
    return null;
  }

  return (
    <Box
      borderBottomWidth={1}
      p={{ base: '2em 5%', md: '3em 5%', xl: '3em 0' }}>
      <Container maxW="7xl">
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacingX="10"
          spacingY="5"
          flexWrap="nowrap"
          alignItems="start">
          {questions.map((q, i) => {
            return (
              <Collapsable question={q.question} answer={q.answer} key={i} />
            );
          })}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
