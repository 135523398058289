import { Box, Container, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { colors } from 'constants/constants';
import Image from 'next/image';
import { SquaresProps } from 'types/umbraco';

export default function SquaresBlock({
  nestedBlocks,
  bgLine,
}: SquaresProps): JSX.Element {
  return (
    <Box p={{ base: '2em 5%', md: '5em 5%', xl: '5em 0' }}>
      <Container maxW="7xl" style={{ position: 'relative' }}>
        <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={10}>
          {nestedBlocks &&
            nestedBlocks.map((block, i) => {
              console.log(i, nestedBlocks.length);
              return (
                <Box
                  backgroundImage={
                    block.blockImage ? block.blockImage._url : ''
                  }
                  backgroundSize={"cover"}
                  key={i}
                  p={{ base: '24px', md: '32px', xl: '57.5px' }}
                  style={{
                    position: 'relative',
                    borderRadius: 12,
                  }}
                  className={`${
                    bgLine && i + 1 < nestedBlocks.length
                      ? 'squareBlock__divider-line'
                      : ''
                  }`}>
                  {block.blockIcon && (
                    <Box
                      pos="relative"
                      w={'20px'}
                      h={'20px'}
                      zIndex={5}
                      className={`${
                        block.blockImage
                          ? 'squareBlock__icon-wrapper--white'
                          : ''
                      }`}>
                      <Image
                        src={`${block.blockIcon._url}`}
                        alt={block.blockIcon.altText ?? block.blockIcon.name}
                        layout="fill"
                        priority={true}
                        loading="eager"
                      />
                    </Box>
                  )}
                  <Heading
                    as="h3"
                    size="md"
                    mt={2}
                    mb={2}
                    fontWeight="600"
                    position="relative"
                    zIndex={5}
                    color={block.blockImage ? colors.white : colors.black}>
                    {block.blockHeading}
                  </Heading>
                  <Text
                    color={block.blockImage ? colors.white : colors.black}
                    position="relative"
                    zIndex={10}
                    fontSize={16}>
                    {block.blockText}
                  </Text>
                  {block.blockImage && (
                    <Box
                      bg={
                        'linear-gradient(0deg, rgba(233, 80, 14, 0.85), rgba(233, 80, 14, 0.85)) '
                      }
                      pos="absolute"
                      w="100%"
                      h="100%"
                      top="0"
                      left="0"
                      opacity={0.85}
                      zIndex="1"
                      borderRadius={12}
                      boxShadow={'0px 8px 36px rgba(0, 0, 0, 0.17)'}
                    />
                  )}
                </Box>
              );
            })}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
