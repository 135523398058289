import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Container, Flex } from '@chakra-ui/react';
import Navigation from './navigation/navigation';
import MobileNavigation from './navigation-mobile/mobilenavigation';
import { useRouter } from 'next/router';
import { elevation } from 'constants/constants';
import { SharedPageProps } from 'components/Layout';

export default function Header({ headerMenu }: SharedPageProps): JSX.Element {
  const prevScrollY = useRef(0);
  const [scrolled, setScrolled] = useState(false);
  const router = useRouter();
  const home = router.route === '/';
  const bgColor = home && !scrolled ? '' : scrolled ? 'white' : 'white';

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      }
      if (window.scrollY <= 0) {
        setScrolled(false);
      }

      prevScrollY.current = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  return (
    <Flex height={home ? '0px' : '60px'} w="100%">
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        zIndex={100}
        height="60px"
        boxShadow={(scrolled || !home) && elevation['+20']}
        w="100%"
        pos={home ? 'fixed' : scrolled ? 'fixed' : 'static'}
        bg={bgColor}
        transition=".15s all ease-in-out">
        <Container maxW="7xl">
          <Flex>
            <Navigation menu={headerMenu} scrolled={scrolled} />
            <MobileNavigation menu={headerMenu} />
          </Flex>
        </Container>
      </Flex>
    </Flex>
  );
}
