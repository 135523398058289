import { useContext } from 'react';
import { useRouter } from 'next/router';
import SessionContext from 'session/SessionContext';
import { Link } from '@chakra-ui/react';
import { colors } from 'constants/constants';

export default function Logout(): JSX.Element {
  const router = useRouter();
  const session = useContext(SessionContext);

  const handleLogout = () => {
    session.actions.signOut().then(() => {
      router.replace('/');
    });
  };

  if (session.user) {
    return (
      <>
        {session.user && (
          <Link onClick={handleLogout} style={{ color: colors.primary[900], fontSize: 14 }}>
            Logga ut
          </Link>
        )}
      </>
    );
  }
  return null;
}
