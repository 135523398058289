import { Box, Flex, Icon, Text, useMediaQuery } from '@chakra-ui/react';
import React, { useState } from 'react';
import Menu from '../../../public/icons/menu.svg';
import Link from 'next/link';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import { colors } from 'constants/constants';
import { Router } from 'next/router';
import MyPages from '../mypages/mypages';
import Logout from '../logout/logout';
import { MainMenu } from 'types/umbraco';

export default function MobileNavigation({
  menu,
}: {
  menu: MainMenu;
}): JSX.Element {
  const [showDrawer, setShowDrawer] = useState(false);
  const [isDesktop] = useMediaQuery('(min-width: 48em)'); // 48em is what md sets.

  const toggleMenu = () => {
    setShowDrawer(!showDrawer);
  };

  React.useEffect(() => {
    if (isDesktop) {
      setShowDrawer(false);
    }
  }, [isDesktop]);

  Router.events.on('routeChangeComplete', () => {
    setShowDrawer(false);
  });

  return (
    <Flex
      w="100%"
      d={{ base: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }}
      alignItems="center"
      justifyContent="flex-end">
      <Box m="0" p="0" lineHeight="0">
        <Icon
          as={Menu}
          w={10}
          h={10}
          onClick={toggleMenu}
          bg={colors.neutrals[900]}
          p="2"
          borderRadius=".5em"
          color={colors.white}
        />

        <Drawer isOpen={showDrawer} placement="left" onClose={toggleMenu}>
          <DrawerOverlay />
          <DrawerContent bg={colors.white} color={colors.neutrals[900]}>
            <DrawerCloseButton />
            <DrawerHeader />

            <DrawerBody>
              <MyPages style={{ marginBottom: 20 }} />
              <Flex
                justifyContent="space-around"
                flex="1"
                flexDir="column"
                lineHeight="2em">
                {menu?.items?.map((x, i) => (
                  <DropDownMenu key={i} {...x} />
                ))}
              </Flex>
            </DrawerBody>

            <DrawerFooter>
              <Logout />
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
    </Flex>
  );
}

const mobileMenuFontSize = '14px';
function DropDownMenu(x) {
  const [show, setShow] = useState(false);

  if (!x.children) {
    return (
      <Link href={x.path}>
        <a style={{ fontSize: mobileMenuFontSize }}>{x.name}</a>
      </Link>
    );
  }

  return (
    <>
      <Text
        style={{ fontSize: mobileMenuFontSize }}
        cursor="pointer"
        onClick={() => setShow(!show)}>
        {x.name} {show ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Text>
      <Flex
        flexDir="column"
        mb="1em"
        px="1em"
        display={show ? 'flex' : 'none'}
        borderLeftWidth={1}
        borderLeftColor={colors.neutrals[300]}>
        {x.children?.map((y, i) => (
          <Link href={`/anlaggning/${y.slug}`} key={i}>
            <a style={{ fontSize: mobileMenuFontSize }}>{y.name}</a>
          </Link>
        ))}
      </Flex>
    </>
  );
}
