import { Box, Container, Flex} from '@chakra-ui/react';
import { colors } from 'constants/constants';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { ImageProps } from 'types/umbraco';

export default function ImageBlock({
  blockMedia,
  
}: ImageProps): JSX.Element {
  const router = useRouter();
  const home = router.route === '/';
console.log(blockMedia, "teest")
  return (
    <Container 
      maxW="4xl"
      pos="relative"
      h="400">
    <Box >
            <Image
              src={blockMedia.media._url}
              layout="fill"
              className="heroObjectFit"
              priority={true}
              loading="eager"
              alt={blockMedia.media.altText ?? 'image'}
            />
            <style jsx global>{`
              .heroObjectFit {
                object-fit: cover;
                
              }
            `}</style>
      
        </Box>
      </Container>
  );
}
