import firebase from 'firebase/app';

async function _fetchApi(
  appendApiPath: boolean,
  path: string,
  options?: RequestInit,
  body?: BodyInit,
  authenticate = true
): Promise<Response> {
  options.headers = options.headers || {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (authenticate) {
    const token = await _setAuth();
    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  if (body) {
    options.body = body;
  }

  let baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  if (!appendApiPath) {
    baseUrl = baseUrl?.split('/api/')[0];
  }

  const response = await fetch(baseUrl + path, options);

  return response;
}

export async function fetchApi(path: string): Promise<Response> {
  return _fetchApi(true, path, { method: 'GET' });
}

export async function postApi<T>(path: string, data: T): Promise<Response> {
  return _fetchApi(true, path, { method: 'POST' }, JSON.stringify(data));
}

export async function fetchBackend(path: string): Promise<Response> {
  return _fetchApi(false, path, { method: 'GET' });
}

export async function _setAuth(): Promise<string> {
  if (firebase && typeof window !== 'undefined') {
    const user = firebase.auth().currentUser;
    if (user) {
      return await user?.getIdToken();
    }
  }
  return '';
}
