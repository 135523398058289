import { Flex } from '@chakra-ui/react';
import styles from '../layout.module.css';

type Props = {
  children: React.ReactNode;
};

export default function Content({ children }: Props): JSX.Element {
  return (
    <Flex className={styles.content} flexDir="column">
      {children}
    </Flex>
  );
}
