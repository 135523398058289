import { Box, Button, Container, SimpleGrid } from '@chakra-ui/react';
import { colors, elevation } from 'constants/constants';
import Image from 'next/image';
import Link from 'next/link';
import { CtaProps } from 'types/umbraco';

export default function CTABlock({
  bgColor,
  textColor,
  text,
  imagePicker,
  ctaButtons,
}: CtaProps): JSX.Element {
  
  return (
    <Box
      p={{ base: '.2rem 5%', md: '5em 5%', xl: '5em 0' }}
      className="umb-cta-block">
      <Container maxW="7xl">
        <Box
          shadow={elevation['+80']}
          bg={{ base: colors.secondary[900], sm: bgColor ? '#' + bgColor : 'black' }}
          color={{ base: colors.white, sm: textColor ? '#' + textColor : 'white' }}
          borderRadius="1em"
          overflow="hidden"
          pos="relative">
          <SimpleGrid columns={!imagePicker ? 1 : resp2Cols}>
            <Box
              h="100%"
              p={respWPad}
              alignContent={!imagePicker ? 'center' : ''}>
              <Box
                mt="1em"
                mb="2em"
                dangerouslySetInnerHTML={{ __html: text }}></Box>

              {ctaButtons && (
                <Box>
                  {ctaButtons.map((ctaBtn, i) => {
                    return (
                      <Link
                        href={
                          ctaBtn.buttonLink._url?.replace('/website', '') ?? '/'
                        }
                        key={i}>
                        <a>
                          <Button variant="ml">{ctaBtn.buttonText}</Button>
                        </a>
                      </Link>
                    );
                  })}
                </Box>
              )}
            </Box>

            {imagePicker && (
              <Box pos="relative" overflow="hidden" w="100%" h={respImg}>
                <Box
                  overflow="hidden"
                  borderRadius="50%"
                  pos="absolute"
                  w={respCircleWH}
                  h={respCircleWH}
                  top={{ base: '0', sm: '50%' }}
                  transform={{
                    base: 'none',
                    sm: 'translateY(-50%)',
                  }}
                  right={respCircleR}
                  left={respCircleL}
                  m={{ base: '0 auto' }}>
                  <Image
                    src={`${imagePicker._url}?width=720`}
                    layout="fill"
                    className="ctaObjectFit"
                    priority={true}
                    loading="eager"
                    alt={imagePicker.altText ?? 'cta image'}
                  />
                  <style jsx global>{`
                    .ctaObjectFit {
                      object-fit: cover;
                    }
                  `}</style>
                </Box>
              </Box>
            )}
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
}

const respImg = {
  base: '300px',
  sm: '100%',
  md: '100%',
  xl: '100%',
};

const resp2Cols = {
  base: 1,
  sm: 2,
  md: 2,
  xl: 2,
};

const respCircleWH = {
  base: '30em',
  lg: '40em',
  xl: '45em',
};

const respWPad = {
  base: '30px',
  sm: '30px',
  md: '50px',
  lg: '100px',
  xl: '100px',
};

const respCircleL = {
  base: '-10em',
  sm: '1em',
  md: 'auto',
  xl: 'auto',
};

const respCircleR = {
  base: '-10em',
  sm: '-10em',
  md: '-10em',
  xl: '-10em',
};
