import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { colors } from 'constants/constants';
import { useRouter } from 'next/router';
import { BookingProps, ContentPage, StartPage } from 'types/umbraco';

export default function BookingBlock({
  heading,
  text,
  textAlignment,
  children,
}: BookingProps): JSX.Element {
  const router = useRouter();
  const home = router.route === '/';


  const textAlign = textAlignment === 'Vänster' ? 'left' : textAlignment === 'Höger' ? 'right' : 'center';
  return (
    <Box
      p={{ base: '2em 5%', md: '5em 5%', xl: '5em 0' }}
      h={{ base: home ? '75vh' : 300, sm: 400, md: 500 }}
      bg={colors.neutrals[100]}
      pos="relative">
      <Container maxW="7xl" h="100%">
        <Flex
          h="100%"
          align={textAlign}
          justify="center"
          pos="relative"
          zIndex="11"
          flexDirection="column">
          <Heading color="white" mb=".5em">
            {heading}
          </Heading>
          <Text color="white">{text}</Text>
          {children}
        </Flex>
      </Container>
      {/* <Booking
            facilities={sharedPageProps.facilities}
            productsByFacility={productsByFacility}
          /> */}

      <Box
        bg={colors.black}
        pos="absolute"
        w="100%"
        h="100%"
        top="0"
        left="0"
        opacity={0.1}
        zIndex="1"
      />
    </Box>
  );
}