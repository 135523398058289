import useSWR from 'swr';
import { CampaignsDto, FacilityDto, ProductDto } from 'types/generic';
import { fetchApi } from './fetchApi';

export async function GetFacilities(): Promise<FacilityDto[]> {
  const facilitiesRes = await fetchApi('/facilities');
  const facilities = facilitiesRes.ok ? await facilitiesRes.json() : [];

  return facilities.sort((a, b) =>
    a.name.localeCompare(b.name)
  ) as FacilityDto[];
}

export async function GetAvailableProductsByFacility(): Promise<{
  [key: string]: ProductDto[];
}> {
  const productsRes = await fetchApi('/products/facilities');
  const products = productsRes.ok ? await productsRes.json() : {};

  return products as { [key: string]: ProductDto[] };
}

export async function GetFacility(slug: string): Promise<FacilityDto | null> {
  const facilityRes = await fetchApi(`/facilities/slug/${slug}`);
  const facility = facilityRes.ok
    ? ((await facilityRes.json()) as FacilityDto)
    : null;

  return facility;
}

export const getCampaigns = async (): Promise<CampaignsDto[] | null> => {
  const campaignRes = await fetchApi('/campaign');
  const campaigns = campaignRes.ok ? (await campaignRes.json()) : null
  return campaigns
}

export async function GetAvailableProductsForFacility(
  slug: string
): Promise<ProductDto[]> {
  const productsRes = await fetchApi(`/products/${slug}/available`);
  const products = productsRes.ok
    ? ((await productsRes.json()) as ProductDto[])
    : null;
  return products;
}

const swrFacilitiesFetcher = () => GetFacilities();
export function useFacilities(): FacilityDto[] {
  const { data } = useSWR(`GetFacilities`, swrFacilitiesFetcher);
  return data;
}

const swrGAPFFetcher = () => GetAvailableProductsByFacility();
export function useAvailableProductsByFacility(): {
  [key: string]: ProductDto[];
} {
  const { data } = useSWR(`GetAvailableProductsByFacility`, swrGAPFFetcher);
  return data;
}
