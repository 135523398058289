import React from 'react'
import ContactFormBlockContent from './ContactFormBlockContent';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ContactFormProps } from 'types/umbraco';

const ContactFormBlock = ({ heading, isCompany, showContact, contentTypeAlias, bgColor, textColor }: ContactFormProps): JSX.Element => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY} language="sv">
            <ContactFormBlockContent heading={heading} isCompany={isCompany} showContact={showContact} contentTypeAlias={contentTypeAlias} bgColor={bgColor} textColor={textColor} />
        </GoogleReCaptchaProvider>
    )
}

export default ContactFormBlock